/* Accordion arrow styles */
/* Remove default plus/minus icons */
.accordion-button::after {
    
    content: none !important; /* Disable the default Bootstrap ::after content */
  }

  .content { height:100vh }

  .accordion-item
  {
    border: 0 !important
  }

.arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(45deg);
    transition: transform 0.3s;
  }
  
  .accordion-button.collapsed .arrow {
    transform: rotate(-135deg); /* Arrow pointing to the right */
  }
  
  .accordion-button:not(.collapsed) .arrow {
    transform: rotate(45deg); /* Arrow pointing down */
  }
  
  /* Alignment */
  .accordion-button {
    padding-left: 0rem; /* Add padding to make space for the arrow */
  }
  
  .accordion-body
  {
    padding-left: 18px !important
  }


  .accordion-body .list-unstyled li
  {
    margin-bottom:7px
  }